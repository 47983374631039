import React, { Component } from 'react';
import LoginPage from '../Modules/Pages/LoginPage/LoginPage.js'
import { MAIN_CONFIG } from '../config/main';

export default class LoginContainer extends Component {

    static async getInitialProps(context) {
        return LoginPage.getInitialProps(context, MAIN_CONFIG.PAGE_TYPES.LOGIN);
    }

    render() {
        return  <LoginPage { ...this.props } />;
    }
}

